<template>
  <v-card color="white">
    <crud-header :title="name" type="Permission" store="permission" />
    <permission />
  </v-card>
</template>

<script type="text/babel">
import permissionModule from "@/store/modules/permission";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import Permission from "../../components/permission/Permission";

const { mapGetters } = createNamespacedHelpers("permission");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "permission",
  permissionModule
);

export default {
  name: "permission-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Permission,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["name", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
