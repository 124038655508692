<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Permission"
      store="permission"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'permissions.show',
            params: { permissionId: id }
          })
      "
    />
    <permission />
  </v-card>
</template>

<script type="text/babel">
import permissionModule from "@/store/modules/permission";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import Permission from "@/components/permission/Permission";

const { mapGetters } = createNamespacedHelpers("permission");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "permission",
  permissionModule
);

export default {
  name: "permission-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Permission,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
