var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Permissions","store":"permissions","headers":[
    { text: 'Name', value: 'name' },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'permissions_view'
    }
  ],"actions":_vm.actions,"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('permission-table-row',{attrs:{"permission-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }