<template>
  <table-overview
    title="Permissions"
    store="permissions"
    :headers="[
      { text: 'Name', value: 'name' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'permissions_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <permission-table-row
      slot="table-row"
      slot-scope="{ item }"
      :permission-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import permissionsModule from "@/store/modules/permissions";
import PermissionTableRow from "../../components/permission/PermissionTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "permissions",
  permissionsModule
);

export default {
  name: "permissions-view",
  components: {
    PermissionTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "permissions.create" },
        rolesOrPermissions: "permissions_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
