<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      autocomplete="not-my-name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("permission");

export default {
  name: "permission",
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed(["name"])
  }
};
</script>
